<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        offset-md="6"
        offset-lg="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          light
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              v-if="item.image"
              :src="getImageUrl(item.image)"
              max-width="300"
              max-height="150"
              contain
            />
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span :class="item.status ? 'success--text' : 'error--text' ">
              <b>
                {{ item.status ? 'Activo' : 'Inactivo' }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              @click="updateDialog(item)"
            >
              mdi-upload
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogs.update"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Actualizar App Header
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span class="font-weight-bold">
                Grupo Alimenticio:
              </span>
              {{ forms.update.title }}
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="forms.update.image"
                label="Imagen"
                show-size
                @change="previewFile"
              />
            </v-col>
            <v-col cols="12">
              <center>
                <v-img
                  v-show="forms.update.preview"
                  :src="forms.update.preview"
                  max-width="300"
                  max-height="300"
                  contain
                />
              </center>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                v-for="(error, i) in forms.update.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="update"
          >
            Actualizar
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.update = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        headers: [
          { text: 'Titulo', value: 'title' },
          { text: 'Imagen', value: 'image' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', value: 'action' },
        ],
        items: [],
        search: null,
        dialogs: {
          update: false,
        },
        forms: {
          update: {
            id: null,
            title: null,
            image: null,
            preview: null,
            errors: [],
          },
        },
      }
    },
    async mounted () {
      await this.getItems()
    },
    methods: {
      async getItems () {
        this.items = await this.$api.FoodGroup.list()
      },
      async updateDialog (item) {
        const form = this.forms.update
        form.id = item.id
        form.title = item.title
        form.image = null
        form.preview = ''
        this.dialogs.update = true
      },
      async update () {
        const form = this.forms.update
        const formData = new FormData()
        formData.append('food_group_id', form.id)
        formData.append('image', form.image)
        try {
          const response = await this.$api.FoodGroupHeaders.create(formData)
          const index = this.items.map(x => x.id).indexOf(form.id)
          this.items[index].image = response
          this.dialogs.update = false
        } catch (err) {
        }
      },
      previewFile (file) {
        const form = this.forms.update
        form.preview = URL.createObjectURL(file)
      },
      getImageUrl (image) {
        // const path = `${this.$store.state.apiUrl}/${image.path}`
        const path = image.path
        return path
      },
    },
  }
</script>
